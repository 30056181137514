<template>
  <section class="card">
    <link rel="stylesheet" type="text/css" href="/assets/css/dashboard.css">
    <transition name="modal" mode="in-out">
      <modal v-if="telaanalitico"
             :closemodal="closeModal"
             :errors="this.$root.errors"
             :title="getModalTitle()"
             :size="'modal-full'">
        <template slot="body">
          <div style="max-height: 450px;overflow:auto">
            <table class="table table-sm table-hover table-striped table-bordered">
              <thead>
                <tr>
                  <th>Código</th>
                  <th>Placa</th>
                  <th>Data</th>
                  <th>Tipo</th>
                  <th>Classificação</th>
                  <th>Fornecedor</th>
                  <th>Serviço</th>
                  <th>Status</th>
                  <th>Valor</th>
                  <th>Desconto</th>
                  <th>Total</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="item in sourceAnalitico" :key="item.Id">
                  <td>
                    <a target="_blank" :href="'/veiculos?page=1&order=Id&direction=desc&requisicao='+item.Id">{{ item.Id }}</a>
                  </td>
                  <td>
                    {{ item.Placa }}
                  </td>
                  <td>
                    {{ item.Dia + '/'+ item.Ano }}
                  </td>
                  <td>
                    {{ item.TipoRequisicao }}
                  </td>
                  <td>
                    {{ item.Classificacao }}
                  </td>
                  <td>
                    {{ item.Fornecedor }}
                  </td>
                  <td>
                    {{ item.Servico }}
                  </td>
                  <td>
                    {{ item.Status }}
                  </td>
                  <td>
                    R$&nbsp;{{ item.Valor.toFixed(2) }}
                  </td>
                  <td>
                    R$&nbsp;{{ item.Desconto.toFixed(2) }}
                  </td>
                  <td>
                    R$&nbsp;{{ (item.Valor - item.Desconto).toFixed(2) }}
                  </td>
                </tr>
              </tbody>
              <tfoot>
                <tr>
                  <td colspan="10"></td>
                  <td>R$&nbsp;{{sumAnalitico}}</td>
                </tr>
              </tfoot>
            </table>

          </div>
        </template>
      </modal>
    </transition>
    <header class="card-header main-header p-3">
      <div class="row h-100">
        <div class="col-lg-4">
          <h2 class="card-title mb-title-2">
            Dashboard Despesas
          </h2>
        </div>
        <div class="col-lg-8">
          <div class="form-row">
            <div class="form-group col-lg-3 mb-0">

              <select class="form-control form-control-sm" v-model="filtro" @change="consultar">
                <option value="DataCadastro">Data Cadastro</option>
                <option value="DataPronto">Data Pronto</option>
                <option value="EmEstoque">Em Estoque</option>
              </select>
            </div>
            <div class="form-group col-lg-3 mb-0">
              <select class="form-control form-control-sm" :disabled="filtro === 'EmEstoque'" v-model="range" @change="changeRange">
                <option v-for="r in dtRange" :value="r.value" :key="r.Id">{{r.id}}</option>
              </select>
            </div>
            <div class="form-group col-lg-2 mb-0">
              <input type="date"
                     class="form-control form-control-sm"
                     id="inicio"
                     :disabled="filtro === 'EmEstoque'"
                     v-model="inicio"
                     @change="changeTextRange" />
            </div>
            <div class="form-group col-lg-2 mb-0">
              <input type="date"
                     class="form-control form-control-sm"
                     v-model="fim"
                     :disabled="filtro === 'EmEstoque'"
                     :min="inicio"
                     @change="changeTextRange" />
            </div>
          </div>
        </div>
      </div>
    </header>
    <div class="p-4">
      <div class="row">
        <div class="col-lg-12">
          <div class="row">
            <div class="col-xl-4">
              <section class="card card-featured-left card-featured-primary mb-3">
                <div class="card-body">
                  <div class="widget-summary">
                    <div class="widget-summary-col widget-summary-col-icon">
                      <div class="summary-icon bg-primary">
                        <i class="fas fa-box-open"></i>
                      </div>
                    </div>
                    <div class="widget-summary-col">
                      <div class="summary">
                        <h4 class="title">Total Geral</h4>
                        <div class="info">
                          <strong class="amount">R$ {{ totalGeral.sum }}</strong>
                        </div>
                      </div>
                      <div class="summary-footer">

                        <a class="text-muted text-uppercase"
                           href="javascript:;"
                           @click="analitico(totalGeral.source)">({{ totalGeral.count }})</a>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
            <div class="col-xl-4">
              <section class="card card-featured-left card-featured-success mb-3">
                <div class="card-body">
                  <div class="widget-summary">
                    <div class="widget-summary-col widget-summary-col-icon">
                      <div class="summary-icon bg-success">
                        <i class="fas fa-car"></i>
                      </div>
                    </div>
                    <div class="widget-summary-col">
                      <div class="summary">
                        <h4 class="title">Quantidade de Chassis</h4>
                        <div class="info">
                          <strong class="amount">{{ totalChassi }}</strong>
                        </div>
                      </div>
                      <div class="summary-footer">

                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
            <div class="col-xl-4">
              <section class="card card-featured-left card-featured-warning mb-3">
                <div class="card-body">
                  <div class="widget-summary">
                    <div class="widget-summary-col widget-summary-col-icon">
                      <div class="summary-icon bg-warning">
                        <i class="fas fa-coins"></i>
                      </div>
                    </div>
                    <div class="widget-summary-col">
                      <div class="summary">
                        <h4 class="title">Média por chassis</h4>
                        <div class="info">
                          <strong class="amount">R$ {{ mediaChassi }}</strong>
                        </div>
                      </div>
                      <div class="summary-footer">

                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>

          </div>
        </div>
        <div class="col-lg-12">
          <div class="row">
            <div class="col-xl-4">
              <section class="card card-featured-left card-featured-secondary mb-3">
                <div class="card-body">
                  <div class="widget-summary">
                    <div class="widget-summary-col widget-summary-col-icon">
                      <div class="summary-icon bg-secondary">
                        <i class="fas fa-hourglass-half"></i>
                      </div>
                    </div>
                    <div class="widget-summary-col">
                      <div class="summary">
                        <h4 class="title">Pendente</h4>
                        <div class="info">
                          <strong class="amount">R$ {{ totalPendente.sum }}</strong>
                        </div>
                      </div>
                      <div class="summary-footer">
                        <span class="pull-left">(CHASSIS: {{ totalChassiPendente }})</span>

                        <a class="text-muted text-uppercase" href="javascript:;" @click="analitico(totalPendente.source)">(REQS.: {{ totalPendente.count }})</a>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
            <div class="col-xl-4">
              <section class="card card-featured-left card-featured-tertiary mb-3">
                <div class="card-body">
                  <div class="widget-summary">
                    <div class="widget-summary-col widget-summary-col-icon">
                      <div class="summary-icon bg-tertiary">
                        <i class="fas fa-thumbs-up"></i>
                      </div>
                    </div>
                    <div class="widget-summary-col">
                      <div class="summary">
                        <h4 class="title">Em Andamento</h4>
                        <div class="info">
                          <strong class="amount">R$ {{ totalAndamento.sum }}</strong>
                        </div>
                      </div>
                      <div class="summary-footer">
                        <span class="pull-left">(CHASSIS: {{ totalChassiAndamento }})</span>

                        <a class="text-muted text-uppercase" href="javascript:;" @click="analitico(totalAndamento.source)">(REQS.: {{ totalAndamento.count }})</a>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
            <div class="col-xl-4">
              <section class="card card-featured-left card-featured-quaternary mb-3">
                <div class="card-body">
                  <div class="widget-summary">
                    <div class="widget-summary-col widget-summary-col-icon">
                      <div class="summary-icon bg-quaternary">
                        <i class="fas fa-folder"></i>
                      </div>
                    </div>
                    <div class="widget-summary-col">
                      <div class="summary">
                        <h4 class="title">Encerrado</h4>
                        <div class="info">
                          <strong class="amount">R$ {{ totalEncerrado.sum }}</strong>
                        </div>
                      </div>
                      <div class="summary-footer">
                        <span class="pull-left">(CHASSIS: {{ totalChassiEncerrado }})</span>

                        <a class="text-muted text-uppercase" href="javascript:;" @click="analitico(totalEncerrado.source)">(REQS.: {{ totalEncerrado.count }})</a>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
        <div class="col-lg-6 mb-3">
          <section class="card">
            <div class="card-body">
              <div id="container" style="margin: 0 auto"></div>
            </div>
          </section>
        </div>
        <div class="col-lg-6 mb-3">
          <section class="card">
            <div class="card-body">
              <div id="container2" style="margin: 0 auto"></div>
            </div>
          </section>
        </div>
        <div class="col-xl-3">
          <section class="card card-featured-left card-featured-secondary mb-3">
            <div class="card-body">
              <div class="widget-summary">
                <div class="widget-summary-col widget-summary-col-icon">
                  <div class="summary-icon bg-secondary">
                    <i class="fas fa-wrench"></i>
                  </div>
                </div>
                <div class="widget-summary-col">
                  <div class="summary">
                    <h4 class="title">Total Preparo</h4>
                    <div class="info">

                      <strong class="amount">R$ {{ totalPreparo.sum }}</strong>
                    </div>
                  </div>
                  <div class="summary-footer">
                    <span class="pull-left">(CHASSIS: {{ totalChassiPreparo }})</span>

                    <a class="text-muted text-uppercase" href="javascript:;" @click="analitico(totalPreparo.source)">(REQS.: {{ totalPreparo.count }})</a>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
        <div class="col-xl-3">
          <section class="card card-featured-left card-featured-warning mb-3">
            <div class="card-body">
              <div class="widget-summary">
                <div class="widget-summary-col widget-summary-col-icon">
                  <div class="summary-icon bg-warning">
                    <i class="fas fa-car"></i>
                  </div>
                </div>
                <div class="widget-summary-col">
                  <div class="summary">
                    <h4 class="title">Total Despachante</h4>
                    <div class="info">
                      <strong class="amount">R$ {{ totalDespachante.sum }}</strong>
                    </div>
                  </div>
                  <div class="summary-footer">
                    <span class="pull-left">(CHASSIS: {{ totalChassiDespachante }})</span>

                    <a class="text-muted text-uppercase" href="javascript:;" @click="analitico(totalDespachante.source)">(REQS.: {{ totalDespachante.count }})</a>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>

        <div class="col-xl-3">
          <section class="card card-featured-left card-featured-tertiary mb-3">
            <div class="card-body">
              <div class="widget-summary">
                <div class="widget-summary-col widget-summary-col-icon">
                  <div class="summary-icon bg-tertiary">
                    <i class="fas fa-hand-holding-usd"></i>
                  </div>
                </div>
                <div class="widget-summary-col">
                  <div class="summary">
                    <h4 class="title">Total Garantia</h4>
                    <div class="info">
                      <strong class="amount">R$ {{ totalGarantia.sum }}</strong>
                    </div>
                  </div>
                  <div class="summary-footer">
                    <span class="pull-left">(CHASSIS: {{ totalChassiGarantia }})</span>

                    <a class="text-muted text-uppercase" href="javascript:;" @click="analitico(totalGarantia.source)">(REQS.: {{ totalGarantia.count }})</a>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>

        <div class="col-xl-3">
          <section class="card card-featured-left card-featured-secondary mb-3">
            <div class="card-body">
              <div class="widget-summary">
                <div class="widget-summary-col widget-summary-col-icon">
                  <div class="summary-icon bg-secondary">
                    <i class="fas fa-folder-open"></i>
                  </div>
                </div>
                <div class="widget-summary-col">
                  <div class="summary">
                    <h4 class="title">Total Pós-Garantia</h4>
                    <div class="info">
                      <strong class="amount">R$ {{ totalPosGarantia.sum }}</strong>
                    </div>
                  </div>
                  <div class="summary-footer">
                    <span class="pull-left">(CHASSIS: {{ totalChassiPosGarantia }})</span>

                    <a class="text-muted text-uppercase" href="javascript:;" @click="analitico(totalPosGarantia.source)">(REQS.: {{ totalPosGarantia.count }})</a>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>

      </div>
      <div class="row pt-4">
        <div class="col-lg-12">
          <section class="card">
            <header class="card-header">
              <div class="card-actions">
                <label>Medida: </label>
                <select class="form-control form-control-sm d-inline-block"
                        v-model="operacao"
                        style="width: 100px"
                        @change="buildEstatisticas()">
                  <option value="Qtd">Quantidade</option>
                  <option value="Soma">Valor</option>
                </select>
                &nbsp;
                &nbsp;
                &nbsp;
                <label>Agrupar por: </label>
                <select class="form-control form-control-sm d-inline-block"
                        v-model="agrupamento"
                        style="width: 55px"
                        @change="buildEstatisticas()">
                  <option value="Dia">Dia</option>
                  <option value="Mes">Mês</option>
                  <option value="Ano">Ano</option>
                </select>
              </div>

              <h2 class="card-title">Evolução desepesas por tempo</h2>
              <p class="card-subtitle">Configure sua visão o campo ao lado</p>
            </header>
            <div class="card-body">
              <div id="container9">

              </div>
            </div>
          </section>
        </div>
      </div>
    </div>

  </section>
</template>

<script>
import api from '@/services/api.js';
import { groupBy, toHighchartPieData, getMonthName, theme } from '@/services/helpers.js';

let dtIni = new Date(new Date().setDate(new Date().getDate() - 7)).toISOString().split('T')[0];
let dtIni15 = new Date(new Date().setDate(new Date().getDate() - 15)).toISOString().split('T')[0];;
let dtIni30 = new Date(new Date().setDate(new Date().getDate() - 30)).toISOString().split('T')[0];;
let dtIni3 = new Date(new Date().setDate(new Date().getDate() - 90)).toISOString().split('T')[0];;
let dtIni6 = new Date(new Date().setDate(new Date().getDate() - 180)).toISOString().split('T')[0];;
let dtIni12 = new Date(new Date().setDate(new Date().getDate() - 365)).toISOString().split('T')[0];
let dtFim = new Date().toISOString().split('T')[0];
var defaultOptions =  $.extend(true, {}, Highcharts.getOptions(), {});

//Dashboard despesas
export default {

    data() {
        const that = this;
        return {
            selecionado: '',
            telaanalitico: false,
            sourceAnalitico: [],
            clone: '',
            loading: false,
            agrupamento: 'Dia',
            operacao: 'Soma',
            dtRange: [
                { id: 'Últimos 7 dias', value: `${dtIni}/${dtFim}` },
                { id: 'Últimos 15 dias', value: `${dtIni15}/${dtFim}` },
                { id: 'Últimos 30 dias', value: `${dtIni30}/${dtFim}` },
                { id: 'Últimos 3 mêses', value: `${dtIni3}/${dtFim}` },
                { id: 'Últimos 6 mêses', value: `${dtIni6}/${dtFim}` },
                { id: 'Último 12 mêses', value: `${dtIni12}/${dtFim}` },
                { id: 'Customizado', value: `` }
            ],
            inicio: dtIni15,
            fim: dtFim,
            range: `${dtIni15}/${dtFim}`,
            data: [],
            filtro: 'DataCadastro',
            totalGeral: {},
            totalPendente: {},
            totalAndamento: {},
            totalEncerrado: {},
            totalPreparo: {},
            totalGarantia: {},
            totalPosGarantia: {},
            totalDespachante: {},
            totalChassi: 0,
            mediaChassi: 0,
            totalChassiPreparo: 0,
            totalChassiGarantia: 0,
            totalChassiPosGarantia: 0,
          totalChassiDespachante: 0,
          totalChassiPendente: 0,
          totalChassiAndamento: 0,
            totalChassiEncerrado: 0,
          sumAnalitico: '',

        };
    },

    methods: {
        closeModal: function () {
            this.sourceAnalitico = [];
            this.telaanalitico = false;
        },
        changeRange: function () {
            let rangeSelected = this.range;
            if (rangeSelected) {
                let ini = rangeSelected.split('/')[0];
                let fim = rangeSelected.split('/')[1];

                this.inicio = ini;
                this.fim = fim;
            }
            this.consultar();
        },
        getModalTitle: function () {
            return 'Analítico';
        },
        changeTextRange: function () {
            let value = `${this.inicio}/${this.fim}`;
            if (this.range !== value) {
                if (this.dtRange.some(r => r.value === value)) {
                    this.range = value;
                }
                else {
                    this.range = '';
                }
            }
            this.consultar();

        },
        build: function () {
          let data = this.data;
          let that = this;

            var auxObj;
            for (var i in data) {
                auxObj = data[i];
                auxObj.Data = new Date(auxObj.Data);
                auxObj.Dia = `${auxObj.Data.getDate().toString().padStart(2, '0')}/${getMonthName(auxObj.Data.getMonth())}`;
                auxObj.Mes = `${getMonthName(auxObj.Data.getMonth())}/${auxObj.Data.getFullYear()}`;
                auxObj.Ano = `${auxObj.Data.getFullYear()}`;
            }

            //totais
            this.totalGeral = {
              count: data.length,
              sum: numberWithCommas(data.length ? data.map(c => c.Valor - c.Desconto).reduce((partial_sum, a) => partial_sum + a) : 0),
              source: data
            };

            this.totalPendente = { source: data.filter(f => f.Status === 'Orçamento' || f.Status === 'Pendente') };
            this.totalPendente.count = this.totalPendente.source.length;
            this.totalPendente.sum = numberWithCommas(this.totalPendente.count ? this.totalPendente.source.map(c => c.Valor - c.Desconto).reduce((partial_sum, a) => partial_sum + a) : 0);

            this.totalAndamento = { source: data.filter(f => f.Status === 'Aprovada' || f.Status === 'Enviado' || f.Status === 'Executado') };
            this.totalAndamento.count = this.totalAndamento.source.length;
            this.totalAndamento.sum = numberWithCommas(this.totalAndamento.count ? this.totalAndamento.source.map(c => c.Valor - c.Desconto).reduce((partial_sum, a) => partial_sum + a) : 0);

            this.totalEncerrado = { source: data.filter(f => f.Status === 'Encerrado') };
            this.totalEncerrado.count = this.totalEncerrado.source.length;
            this.totalEncerrado.sum = numberWithCommas(this.totalEncerrado.count ? this.totalEncerrado.source.map(c => c.Valor - c.Desconto).reduce((partial_sum, a) => partial_sum + a) : 0);


            //Chassi
            this.totalChassi = Object.keys(groupBy(this.totalGeral.source, 'EstoqueId')).length;
            this.totalChassiPendente = Object.keys(groupBy(this.totalPendente.source, 'EstoqueId')).length;
            this.totalChassiEncerrado = Object.keys(groupBy(this.totalEncerrado.source, 'EstoqueId')).length;
            this.totalChassiAndamento = Object.keys(groupBy(this.totalAndamento.source, 'EstoqueId')).length;


            this.mediaChassi = !this.totalGeral.count ? 0 : numberWithCommas(parseInt(this.totalGeral.sum.replace('.', '')) / this.totalChassi);

            //Pie classificacação
            var classificacaoPie = groupBy(data, 'Classificacao');
            classificacaoPie = toHighchartPieData(classificacaoPie);


            //Pie tipo requisicao
            var tipoPie = groupBy(data, 'Status');
            tipoPie = toHighchartPieData(tipoPie);

            Highcharts.chart('container', {
                chart: {
                    plotBackgroundColor: null,
                    plotBorderWidth: null,
                    plotShadow: false,
                    type: 'pie',
                },
                title: {
                    text: 'Geral por classificação'
                },
                tooltip: {
                    pointFormat: '{series.name}: <b>{point.percentage:.1f}% <small>(R$ {point.valor})</small></b>'
                },
                plotOptions: {
                    pie: {
                        allowPointSelect: true,
                        cursor: 'pointer',
                        dataLabels: {
                            enabled: true,
                            format: '<b>{point.name}</b>: {point.percentage:.1f}% <small>(R$ {point.valor})</small>',
                            style: {
                                color: (Highcharts.theme && Highcharts.theme.contrastTextColor) || 'black'
                            }
                      },
                      point: {
                        events: {
                          click: function (oEvent) {
                            that.analiticoClassificacao(oEvent.point.name);
                          }
                        }
                      }
                    }
                },
                series: [{
                    name: 'Classificação',
                    colorByPoint: true,
                    data: classificacaoPie
                }]
            });


            // Apply the theme
            Highcharts.setOptions(theme);

            Highcharts.chart('container2', {
                chart: {
                    plotBackgroundColor: null,
                    plotBorderWidth: null,
                    plotShadow: false,
                    type: 'pie'
                },
                title: {
                    text: 'Geral por Status'
                },
                tooltip: {
                    pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b> <small>(R$ {point.valor})</small>'
                },
                plotOptions: {
                    pie: {
                        allowPointSelect: true,
                        cursor: 'pointer',
                        dataLabels: {
                            enabled: true,
                            format: '<b>{point.name}</b>: {point.percentage:.1f}% <small>(R$ {point.valor})</small>',
                            style: {
                                color: (Highcharts.theme && Highcharts.theme.contrastTextColor) || 'black'
                            }
                        }
                    }
                },
                series: [{
                    name: 'Despesa',
                    colorByPoint: true,
                    data: tipoPie
                }]
            });

            Highcharts.setOptions(defaultOptions);

            //totais
            this.totalPreparo = { source: data.filter(f => f.TipoRequisicao !== 'Garantia' && f.TipoRequisicao !== 'Pós Garantia' && f.TipoRequisicao !== 'Despachante') };
            this.totalPreparo.count = this.totalPreparo.source.length;
            this.totalPreparo.sum = numberWithCommas(this.totalPreparo.count ? this.totalPreparo.source.map(c => c.Valor - c.Desconto).reduce((partial_sum, a) => partial_sum + a) : 0);

            this.totalGarantia = { source: data.filter(f => f.TipoRequisicao === 'Garantia') };
            this.totalGarantia.count = this.totalGarantia.source.length;
            this.totalGarantia.sum = numberWithCommas(this.totalGarantia.count ? this.totalGarantia.source.map(c => c.Valor - c.Desconto).reduce((partial_sum, a) => partial_sum + a) : 0);

            this.totalPosGarantia = { source: data.filter(f => f.TipoRequisicao === 'Pós Garantia') };
            this.totalPosGarantia.count = this.totalPosGarantia.source.length;
            this.totalPosGarantia.sum = numberWithCommas(this.totalPosGarantia.count ? this.totalPosGarantia.source.map(c => c.Valor - c.Desconto).reduce((partial_sum, a) => partial_sum + a) : 0);

            this.totalDespachante = { source: data.filter(f => f.TipoRequisicao === 'Despachante') };
            this.totalDespachante.count = this.totalDespachante.source.length;
            this.totalDespachante.sum = numberWithCommas(this.totalDespachante.count ? this.totalDespachante.source.map(c => c.Valor - c.Desconto).reduce((partial_sum, a) => partial_sum + a) : 0);


            //Chassi
            this.totalChassiPreparo = Object.keys(groupBy(this.totalPreparo.source, 'EstoqueId')).length;
            this.totalChassiGarantia = Object.keys(groupBy(this.totalGarantia.source, 'EstoqueId')).length;
            this.totalChassiPosGarantia = Object.keys(groupBy(this.totalPosGarantia.source, 'EstoqueId')).length;
            this.totalChassiDespachante = Object.keys(groupBy(this.totalDespachante.source, 'EstoqueId')).length;

            this.buildEstatisticas();
        },
        consultar: function () {
            let that = this;
            api.
                get(`/relatorios/dashboarddespesas?dataIni=${escape(this.inicio)}&dataFim=${escape(this.fim)}&filtro=${escape(this.filtro)}`)
              .then(response => {
                    that.data = response.data;
                    that.build();
                });
        },
        buildEstatisticas: function () {
            let data = this.data;
            //Pie classificacação
            var geralChartData = groupBy(data, this.agrupamento);
            var categories = [];
            for (let i in geralChartData) {
                categories.push(i);
            }

            var series = [];
            series.push({
                name: 'Total',
                data: categories.map(c => {
                    let arr = geralChartData[c];
                    return arr.length ? this.operacao === 'Soma' ? (parseInt(arr.map(c => c.Valor - c.Desconto).reduce((partial_sum, a) => partial_sum + a))) : arr.length : 0;
                }),
                type: 'column'
            });
            var dataGeralPorTipo = groupBy(data, 'TipoRequisicao');
            for (let i in dataGeralPorTipo) {
                series.push({
                    name: i, data: categories.map(c => {
                        let arr = geralChartData[c].filter(t => t.TipoRequisicao === i);
                        return arr.length ? this.operacao === 'Soma' ? (parseInt(arr.map(c => c.Valor - c.Desconto).reduce((partial_sum, a) => partial_sum + a))) : arr.length : 0;
                    }), type: 'spline'
                });
            }

            geralChartData = { categories: categories, series: series };

            Highcharts.chart('container9', {
                chart: {
                    zoomType: 'xy'
                },
                title: {
                    text: 'Estatísticas'
                },
                subtitle: {
                    text: 'Agrupado por ' + this.agrupamento
                },
                xAxis: [{
                    categories: geralChartData.categories,
                    crosshair: true
                }],
                yAxis: [{ // Primary yAxis
                    labels: {
                        format: (this.operacao === 'Soma' ? 'R$ ' : '') + '{value}',
                        style: {
                            color: Highcharts.getOptions().colors[1]
                        }
                    },
                    title: {
                        text: this.operacao === 'Soma' ? 'Valor' : 'Quantidade',
                        style: {
                            color: Highcharts.getOptions().colors[1]
                        }
                    }
                }],
                plotOptions: {
                    series: {
                        dataLabels: {
                            enabled: true
                        },
                    }
                },
                tooltip: {
                    shared: true
                },
                responsive: {
                    rules: [{
                        condition: {
                            maxWidth: 500
                        },
                        chartOptions: {
                            legend: {
                                layout: 'horizontal',
                                align: 'center',
                                verticalAlign: 'bottom'
                            }
                        }
                    }]
                },
                //legend: {
                //    layout: 'vertical',
                //    align: 'left',
                //    x: 120,
                //    verticalAlign: 'top',
                //    y: 100,
                //    floating: true,
                //    backgroundColor: (Highcharts.theme && Highcharts.theme.legendBackgroundColor) || 'rgba(255,255,255,0.25)'
                //},
                series: geralChartData.series
            });
        },
      analitico: function (source) {
        this.sumAnalitico = (source.length ? source.map(c => c.Valor - c.Desconto).reduce((partial_sum, a) => partial_sum + a) : 0).toFixed(2);
        this.sourceAnalitico = source;
        this.telaanalitico = true;
        },
      analiticoClassificacao: function (filter) {
        let data = this.data.filter(f => f.Classificacao === filter);
        this.analitico(data);
      }

    },
    mounted: function () {
      this.consultar();
    },

};
</script>

<style scoped>
  
.card {
    height: auto;
}

select.form-control-sm, select.form-control-lg {
    height: 100% !important;
}

@media (max-width: 991px) {
    .mb-title-2 {
        margin-bottom: 0.5rem !important;
    }
}

</style>
